
import { CLEAR_REDUX_STATE, LOGGED_IN, LOGGED_OUT, CHECKED_SESSION, VERIFICATION_CODE_SENT, PASSWORD_CHANGE_SUCCESS,
  LOGGING_OUT, CLEAR_ERROR, CLEAR_PWD_CHANGE_FLG, NEW_USER, CHECKING_SESSION_ERROR_CODE, CODE_FOR_TOTP, SETUP_TOTP_REQUIRED,
  SEND_VERIFICATION_CODE_ERROR_CODE, LOGGED_IN_ERROR_CODE, PASSWORD_CHANGE_ERROR_CODE, TOTP_VERIFIED, TOTP_REQUIRED,
  CLEAR_TOTP_STATE, NEW_USER_PWD_SET } from '../actions/action_generators/PreferablyActions';

import { SESSION_EXPIRED } from '../utilities/AppConstants';

const initialState = { error: null, token: null, email: null, username: null, loggingOut: false, userAttributes: [], newUser: false,
  cognitoUser: {}, sessionExpired: false, pwdChangeSuccess: null, totpSetupRequired: false };

function session (state = initialState, action) {
  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case LOGGED_IN:
    return Object.assign({}, state, {
      token: action.token,
      sessionExpired: false,
      username: action.username,
      totpRequired: false,
      totpSetupRequired: false
    });

  case LOGGED_OUT:
    return initialState;

  case SETUP_TOTP_REQUIRED:
    return Object.assign({}, state, {
      userAttributes: action.userAttributes,
      totpSetupRequired: true,
      username: action.username,
      cognitoUser: action.cognitoUser
    });

  case CODE_FOR_TOTP:
    return Object.assign({}, state, {
      totpCode: action.code,
      totpSetupRequired: true
    });

  case TOTP_VERIFIED:
    return Object.assign({}, state, {
      totpCode: null,
      totpVerified: true,
      totpSetupComplete: action.setup,
      cognitoUser: null
    });

  case TOTP_REQUIRED:
    return Object.assign({}, state, {
      totpRequired: true,
      username: action.username,
      cognitoUser: action.cognitoUser
    });

  case CHECKED_SESSION:
    return Object.assign({}, state, {
      token: action.token
    });

  case VERIFICATION_CODE_SENT:
    return Object.assign({}, state, {
      email: action.emailAddress
    });

  case PASSWORD_CHANGE_SUCCESS:
    return Object.assign({}, state, {
      token: action.token,
      username: action.username,
      sessionExpired: false,
      cognitoUser: null,
      pwdChangeSuccess: true
    });

  case LOGGING_OUT:
    return Object.assign({}, state, { loggingOut: true });

  case CLEAR_ERROR:
    return Object.assign({}, state, {
      error: null
    });

  case CLEAR_PWD_CHANGE_FLG:
    return Object.assign({}, state, {
      pwdChangeSuccess: null
    });


  case CLEAR_TOTP_STATE:
    return Object.assign({}, state, {
      totpRequired: false,
      totpCode: null,
      totpSetupComplete: false,
      totpVerified: false
    });

  case NEW_USER:
    return Object.assign({}, state, {
      userAttributes: action.userAttributes,
      newUser: true,
      username: action.username,
      cognitoUser: action.cognitoUser
    });

  case NEW_USER_PWD_SET:
    return Object.assign({}, state, {
      newUser: false
    });


  case CHECKING_SESSION_ERROR_CODE:

    var result = {};
    result.error = action.error;
    if (action.error && action.error.errorCode === SESSION_EXPIRED) {
      // set the session expired state
      result.sessionExpired = true;

      // clear logged in user information but keep username so we can resume session
      result.token = null;
    } else {
      // all other errors, we assume we are hard stop on session and go to sign in page
      result.token = null;
      result.username = null;
    }

    return Object.assign({}, state, result);

  case SEND_VERIFICATION_CODE_ERROR_CODE:
  case LOGGED_IN_ERROR_CODE:
  case PASSWORD_CHANGE_ERROR_CODE:

    return Object.assign({}, state, {
      error: action.error
    });

  default:
    return state;
  }
}

export default session;