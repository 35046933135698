import { FLAT } from '../utilities/AppConstants';

export const getBaseGeoEventJSON = () => {
  return { pcBounce: [], u_pcVisit: [], pcSubscribe: [], pcUnsubscribe: [], retrieved: 0 };
};

export const getBaseCurrentPriorPeriodJSON = () => {
  return {
    currentPeriod: {
      u_pcVisit:{ devices: { desktop: 0, mobile: 0, tablet: 0, unknown: 0 }, total: 0, trend: null, trendIndicator: FLAT },
      pcBounce:{ devices: { desktop: 0, mobile: 0, tablet: 0, unknown: 0 }, total: 0, trend: null, trendIndicator: FLAT },
      pcSubscribe:{ devices: { desktop: 0, mobile: 0, tablet: 0, unknown: 0 }, total: 0, trend: null, trendIndicator: FLAT },
      pcUnsubscribe:{ devices: { desktop: 0, mobile: 0, tablet: 0, unknown: 0 }, total: 0, trend: null, trendIndicator: FLAT } },
    priorPeriod: {
      pcBounce:{ total: 0 },
      u_pcVisit:{ total: 0 },
      pcSubscribe:{ total: 0 },
      pcUnsubscribe:{ total: 0 },
    },
    retrieved: 0
  };
};