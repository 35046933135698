// import * as immutable from 'object-path-immutable';
import { CLEAR_REDUX_STATE, CLEAR_NOTIFICATION } from '../actions/action_generators/PreferablyActions';
const initialState = { code: null };

function applicationNotification(state = initialState, action) {
  const endsWithActionCompleted = action.type.endsWith('_ACTION_COMPLETED');
  if (endsWithActionCompleted) {

    return Object.assign({}, state, {
      code: action.type
    });
  }

  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case CLEAR_NOTIFICATION:
    return Object.assign({}, state, {
      code: null
    });

  default:
    return state;
  }
}

export default applicationNotification;
