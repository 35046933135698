// import * as immutable from 'object-path-immutable';

import { CLEAR_REDUX_STATE, CLEAR_PREF_DEPLOY_CODE, CLEAR_PREF_CTR_CONTENT, FETCHED_TEMPLATE, FETCHED_PREVIEW,
  FETCHED_DEPLOYMENT_CODE, FETCHED_BUILDER_PREVIEW, CLEAR_PREF_CENTER_TEMPLATE, FETCHED_CONTENT_DATA,
  REMOVE_CONTENT_DATA, FETCHING_CONTENT_DATA_ERROR_CODE, FETCHING_PREVIEW_ERROR_CODE,
  FETCHING_S3_SIGNITURE_ERROR_CODE, FETCHING_DEPLOYMENT_CODE_ERROR_CODE, FETCHING_TEMPLATE_ERROR_CODE,
  APPLY_DESIGN_PROPERTY, SET_DESIGN_MODAL_DISPLAYED } from '../actions/action_generators/PreferablyActions';

const initialState = { deploymentCode: '', prefCenterContent: {}, prefCenterContentData: {},
  templateHTML: {}, prefCenterDesignContext: {}, designModalDisplayed: false };

function templates(state = initialState, action) {
  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case CLEAR_PREF_DEPLOY_CODE: {
    const newState = structuredClone(state);
    newState.deploymentCode = '';
    return newState;
  }

  case SET_DESIGN_MODAL_DISPLAYED:  {
    const newState = structuredClone(state);
    newState.designModalDisplayed = action.displayed;
    return newState;
  }

  case APPLY_DESIGN_PROPERTY: {
    const newState = structuredClone(state);
    if (!newState.prefCenterDesignContext[action.instanceId]) {
      newState.prefCenterDesignContext[action.instanceId] = {};
    }
    newState.prefCenterDesignContext[action.instanceId] =
        Object.assign(newState.prefCenterDesignContext[action.instanceId], action.designObject);
    return newState;
  }

  case CLEAR_PREF_CTR_CONTENT: {
    const newState = structuredClone(state);
    newState.prefCenterContent = {};
    return newState;
  }

  case FETCHED_TEMPLATE: {
    const newState = structuredClone(state);
    if (action.stateProp) {
      newState.templateHTML[action.stateProp] = action.template;

    } else {
      newState.template = action.template;
    }

    return newState;
  }

  case FETCHED_PREVIEW: {
    const newState = structuredClone(state);
    newState.preview = action.preview;
    return newState;
  }

  case FETCHED_DEPLOYMENT_CODE: {
    const newState = structuredClone(state);
    newState.deploymentCode = action.deploymentCode;
    return newState;
  }

  case FETCHED_BUILDER_PREVIEW: {
    const newState = structuredClone(state);
    newState.builderPreview = action.builderPreview;
    return newState;
  }

  case CLEAR_PREF_CENTER_TEMPLATE: {
    const newState = structuredClone(state);
    newState.builderTemplate = null;
    return newState;
  }

  case FETCHED_CONTENT_DATA: {
    const newState = structuredClone(state);
    newState.prefCenterContentData[action.instanceId] = action.contextData;
    return newState;
  }

  case REMOVE_CONTENT_DATA: {
    const newState = structuredClone(state);
    delete newState.prefCenterContentData[action.id];
    return newState;
  }

  case FETCHING_CONTENT_DATA_ERROR_CODE:
  case FETCHING_PREVIEW_ERROR_CODE:
  case FETCHING_S3_SIGNITURE_ERROR_CODE:
  case FETCHING_DEPLOYMENT_CODE_ERROR_CODE:
  case FETCHING_TEMPLATE_ERROR_CODE: {
    const newState = structuredClone(state);
    newState.error = action.error;
    return newState;
  }

  default:
    return state;
  }
}
export default templates;
