import * as immutable from 'object-path-immutable';

import { CLEAR_REDUX_STATE, CLEAR_IP_FIELDS, CLEAR_IP_OBJECTS, FETCHED_CLIENT, FETCHED_ADMIN_CLIENT_RECORD,
  CLEAR_ADMIN_CLIENT_RECORD, MAPPED_LISTS, UPDATED_CLIENT_ACTION_COMPLETED, UPDATED_CONNECTOR_ACTION_COMPLETED,
  FETCHED_I18N_MESSAGES, FETCHED_INT_OBJECTS, FETCHED_INT_FIELDS, FETCHED_INT_PARTNER_LISTS, FETCHING_INT_FIELDS_ERROR_CODE,
  FETCHING_INT_PARTNER_LISTS_ERROR_CODE, UPDATING_CLIENT_ERROR_CODE, UPLOAD_TEMPLATE_ERROR_CODE,
  FETCHING_CLIENT_ERROR_CODE, FETCHING_INT_OBJECTS_ERROR_CODE,
  TEST_CONNECTOR_ERROR_CODE, UPDATE_CONNECTOR_ERROR_CODE,
  PERFORM_PARTNER_OP_ERROR_CODE } from '../actions/action_generators/PreferablyActions';

const initialState = { client: {}, fullClient: {}, error: null, intObjects: {}, intFields: {}, intLists: {},
  keyedIntegrationPartners: {}, allIntegrationPartners: [], mappedLists: {} };

function clients(state = initialState, action) {
  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case  CLEAR_IP_FIELDS:
    delete state.intFields[action.integrationPartnerName];
    return state;

  case  CLEAR_IP_OBJECTS:
    delete state.intObjects[action.integrationPartnerName];
    return state;

  case FETCHED_CLIENT: {
    const newState = Object.assign({}, state);
    newState.client = action.client;

    removeInactiveIntegrationPartners(newState);
    return newState;
  }

  case FETCHED_ADMIN_CLIENT_RECORD: {
    const newState = Object.assign({}, state);
    newState.fullClient = action.client;
    return newState;
  }

  case CLEAR_ADMIN_CLIENT_RECORD: {
    const newState = Object.assign({}, state);
    newState.fullClient = {};
    return newState;
  }


  case MAPPED_LISTS: {
    const newState = {
      ...state,
      mappedLists: action.lists
    };

    return newState;
  }

  case UPDATED_CLIENT_ACTION_COMPLETED: {
    const newState = structuredClone(state);
    Object.assign(newState.client, action.client);

    removeInactiveIntegrationPartners(newState);

    return newState;
  }

  case UPDATED_CONNECTOR_ACTION_COMPLETED: {
    const newState = {
      ...state,
      client: action.client
    };

    removeInactiveIntegrationPartners(newState);

    return newState;
  }

  case FETCHED_I18N_MESSAGES:
    return {
      ...state,
      i18N: action.messages
    };

  case FETCHED_INT_OBJECTS: {
    const newObjects = {};
    newObjects.objects = action.data;
    const newInfo = state.intObjects;
    newInfo[action.ip] = newObjects;

    return Object.assign({}, state, {
      intObjects: newInfo
    });
  }

  case FETCHED_INT_FIELDS: {
    const newFields = {
      object: action.object,
      fields: action.data
    };

    const newInfo = state.intFields;
    newInfo[action.ip] = newFields;
    var newObj = immutable.set(state, 'intFields', newInfo);
    return newObj;
  }

  case FETCHED_INT_PARTNER_LISTS: {
    const newState = structuredClone(state);
    newState.intLists[action.ip] = {};
    newState.intLists[action.ip][action.channel] = action.data;
    return newState;
  }

  case FETCHING_INT_FIELDS_ERROR_CODE: {
    const newInfo = state.intFields;
    newInfo[action.ip] = 'error';
    const newObj = immutable.set(state, 'intFields', newInfo);
    return newObj;
  }

  case FETCHING_INT_PARTNER_LISTS_ERROR_CODE:
  case FETCHING_CLIENT_ERROR_CODE:
  case UPDATING_CLIENT_ERROR_CODE:
  case UPLOAD_TEMPLATE_ERROR_CODE:
  case FETCHING_INT_OBJECTS_ERROR_CODE:
  case TEST_CONNECTOR_ERROR_CODE:
  case UPDATE_CONNECTOR_ERROR_CODE:
  case PERFORM_PARTNER_OP_ERROR_CODE:

    var result = Object.assign({}, state, {
      error: action.error
    });

    return result;

  default:
    return state;
  }
}

function removeInactiveIntegrationPartners(newState) {
  // filter out any inactive integration partners
  // setup keyed access to integration partners (in addition to integrationPartners array on the client object/record)
  let activeKeyedIntegrationPartners = {};
  let activeIntegrationPartners = [];
  newState.allIntegrationPartners = newState.client.integrationPartners;

  if (newState.client && newState.client.integrationPartners) {
    for (let ii = 0; ii < newState.client.integrationPartners.length; ii++) {
      if (newState.client.integrationPartners[ii] &&newState.client.integrationPartners[ii].active) {
        activeKeyedIntegrationPartners[newState.client.integrationPartners[ii].id] = newState.client.integrationPartners[ii];
        activeIntegrationPartners.push(newState.client.integrationPartners[ii]);
      }
    }

    newState.client.integrationPartners = activeIntegrationPartners;
    newState.keyedIntegrationPartners = activeKeyedIntegrationPartners;
  }
}

export default clients;