import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import loadable from '@loadable/component';

import { Router, Route, hashHistory } from 'react-router';
import { IntlProvider } from 'react-intl';
import * as browserLocale from 'locale2';

let localMessages = null;
let messages = null;

// for demo of spanish
// import messages_es from './translations/es';
// messages = {es: messages_es};

if (messages && messages[browserLocale]) {
  localMessages = messages[browserLocale];
}

const App = loadable(() => import('./components/App'));
const MainContent = loadable(() => import('./components/MainContent'));
const CreateUpdatePrefCenter = loadable(() => import('./components/Preferences/CreateUpdatePrefCenter/CreateUpdatePrefCenter'));

const DashboardContainer = loadable(() => import('./components/Reports/DashboardContainer'));
const Preference = loadable(() => import('./components/Preferences/Preference'));
const PreferenceCenter = loadable(() => import('./components/Preferences/PreferenceCenter'));
const PrefHome = loadable(() => import('./components/Preferences/PrefHome'));

const Reports = loadable(() => import('./components/Reports/Reports'));
const Admin = loadable(() => import('./components/Admin/Admin'));

const ForgotPassword = loadable(() => import('./components/SignIn/ForgotPassword'));
const PasswordReset = loadable(() => import('./components/SignIn/PasswordReset'));

const UserAccount = loadable(() => import('./components/Account/UserAccount'));
// const NewPassword = loadable(() => import('./components/SignIn/NewPassword'));

const Login = loadable(() => import('./components/SignIn/Login'));
const SignInForm = loadable(() => import('./components/SignIn/SignInForm'));

render((
  <IntlProvider locale={browserLocale} defaultLocale={browserLocale} messages={localMessages}>
    <Provider store={store}>
      <Router history={hashHistory}>
        <Route component={Login}>
          <Route path='/' component={SignInForm}/>
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/pass-reset' component={PasswordReset} />
        </Route>
        <Route component={App}>
          <Route component={MainContent}>
            <Route path='/dashboard' component={DashboardContainer} />

            <Route component={Preference}>
              <Route path='/experiences' component={PrefHome} />
            </Route>

            <Route component={PreferenceCenter}>
              <Route path='/pref-center' component={CreateUpdatePrefCenter}/>
            </Route>
            <Route path='/reports' component={Reports} />
            <Route path='/admin' component={Admin} />
            <Route path='/account' component={UserAccount} />
          </Route>
        </Route>
      </Router>
    </Provider>
  </IntlProvider>
), document.getElementById('app-container'));

// pre-fetch async components
Login.load().catch( (e) => {console.log(e);});
SignInForm.load().catch( (e) => {console.log(e);});

App.load().catch( (e) => {console.log(e);});
MainContent.load().catch( (e) => {console.log(e);});
ForgotPassword.load().catch( (e) => {console.log(e);});
PasswordReset.load().catch( (e) => {console.log(e);});
CreateUpdatePrefCenter.load().catch( (e) => {console.log(e);});
Preference.load().catch( (e) => {console.log(e);});
PrefHome.load().catch( (e) => {console.log(e);});
PreferenceCenter.load().catch( (e) => {console.log(e);});
DashboardContainer.load().catch( (e) => {console.log(e);});
Reports.load().catch( (e) => {console.log(e);});
Admin.load().catch( (e) => {console.log(e);});
UserAccount.load().catch( (e) => {console.log(e);});