import { CLEAR_REDUX_STATE, FETCHED_PREFCENTERS, SET_CURRENT_PREFCENTER, SET_CURRENT_PREF_CENTER_TAB,
  RESET_PREF_CTR_UPDATE_ACTIVE, SET_CURRENT_SETTING_TAB, DELETED_PREF_CENTER_DEPLOYMENT_ACTION_COMPLETED, UPDATED_PREFCENTER,
  DELETED_PREF_CENTER_ACTION_COMPLETED, UPLOADED_PREFCENTER_IMAGE, UPDATED_PREF_CENTER_DEPLOYMENT,
  UPDATE_SESSION_PREF_CENTER, ADD_SELECTED_ITEM, REMOVE_SELECTED_ITEM, REMOVE_ALL_SELECTED_ITEMS, UPDATING_PREFCENTER,
  UPDATING_PREFCENTER_ERROR_CODE, CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE, FETCHING_PREFCENTERS_ERROR_CODE,
  UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE, DELETING_PREFCENTER_ERROR_CODE, SET_PREV_GREET_MSG_DISPLAY }
  from '../actions/action_generators/PreferablyActions';

import { removeNonUpdatablePrefCenterProps } from '../actions/prefCenterPropsUtil';

const initialState = { items: [], prefCenter: {},
  sessionBaseline: null, updateActive: false, updateStart: null,
  imageURL: '', currentSettingTab: '', appProfile: null,
  emailListSelectedState: {}, interestSelectedState: {}, interestOptionSelectedState: {} };

function preferenceCenters(state = initialState, action) {
  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case FETCHED_PREFCENTERS: {
    const newState = Object.assign({}, state, {
      items: action.items,
      receivedAt: action.receivedAt
    });

    return newState;
  }

  case SET_CURRENT_PREFCENTER:
    return Object.assign({}, state, {
      prefCenter: action.prefCenter,
      lastDBChange: action.prefCenter.lastChange,
      previewGreetDisplay: false,
      sessionBaseline: removeNonUpdatablePrefCenterProps(structuredClone(action.prefCenter))
    });

  case SET_CURRENT_PREF_CENTER_TAB:
    return Object.assign({}, state, {
      currentPrefCenterTab: action.tab
    });

  case RESET_PREF_CTR_UPDATE_ACTIVE:
    return Object.assign({}, state, {
      updateActive: false
    });


  case SET_CURRENT_SETTING_TAB:
    return Object.assign({}, state, {
      currentSettingTab: action.settingTab
    });

  case SET_PREV_GREET_MSG_DISPLAY:
    return Object.assign({}, state, {
      previewGreetDisplay: action.display
    });

  case DELETED_PREF_CENTER_DEPLOYMENT_ACTION_COMPLETED:
  case UPDATED_PREFCENTER:
    if (!action.prefCenter) {
      return state;
    }
    var anotherState = structuredClone(state);

    anotherState.updateActive = false;
    anotherState.updateStart = null;

    // assign to the baseline new props/updated props
    // Object.assign(anotherState.sessionBaseline, action.prefCenter);
    anotherState.sessionBaseline = removeNonUpdatablePrefCenterProps(structuredClone(action.prefCenter));
    anotherState.lastDBChange = action.prefCenter.lastChange;
    anotherState.prefCenter = structuredClone(action.prefCenter);

    // update items in collection of pref centers
    var shouldAdd = true;
    var foundIndex;

    anotherState.items.map((val, index) => {
      if (val.id === action.prefCenter.id) {
        shouldAdd = false;
        foundIndex = index;
      }
    });

    if (shouldAdd) {
      anotherState.items.push(action.prefCenter);
    }
    else {
      anotherState.items.splice(foundIndex, 1, action.prefCenter);
    }

    return anotherState;

  case DELETED_PREF_CENTER_ACTION_COMPLETED: {
    let removePrefCenter = (state, prefCenter) => {
      return state.items.filter((val) => {
        return val.id !== prefCenter.id;
      });
    };

    const newState = Object.assign({}, state);
    newState.items = removePrefCenter(state, action.prefCenter);
    // newState.prefCenter = newState.items[0];

    return newState;
  }

  case UPLOADED_PREFCENTER_IMAGE:
    return Object.assign({}, state, {
      imageURL: action.imageURL
    });


  case UPDATED_PREF_CENTER_DEPLOYMENT:
    return Object.assign({}, state, {
      deployment: action.prefCenterDeployment,
      prefCenter: action.preferenceCenter
    });

  case UPDATE_SESSION_PREF_CENTER: {
    console.log('updating session state...');
    const newState = structuredClone(state);
    newState.prefCenter = action.prefCenter;
    return newState;
  }

  case ADD_SELECTED_ITEM: {
    const newState = structuredClone(state);
    newState[action.selectedType + 'SelectedState'][action.id] = action.displayState;
    return newState;
  }

  case REMOVE_SELECTED_ITEM: {
    const newState = structuredClone(state);
    delete newState[action.selectedType + 'SelectedState'][action.id];
    return newState;
  }

  case REMOVE_ALL_SELECTED_ITEMS: {
    const newState = structuredClone(state);
    if (action.selectedType) {
      newState[action.selectedType + 'SelectedState'] = {};
    } else {
      newState.emailListSelectedState = {};
      newState.interestSelectedState = {};
      newState.interestOptionSelectedState = {};
    }

    return newState;
  }

  case UPDATING_PREFCENTER: {
    var updatedState = structuredClone(state);
    const newState = Object.assign({}, updatedState, { updateActive: true, updateStart: Date.now() });
    return newState;
  }

  case UPDATING_PREFCENTER_ERROR_CODE: {
    const updatedState = structuredClone(state);
    const newState = Object.assign({}, updatedState, { updateActive: false, updateStart: null });
    return newState;
  }

  case CONCURRENT_UPDATE_ERROR_WEB_EXP_ERROR_CODE: {
    const updatedState = structuredClone(state);
    const newState = Object.assign({}, updatedState, { updateActive: false });
    return newState;
  }

  case FETCHING_PREFCENTERS_ERROR_CODE:
  case UPDATING_PREF_CENTER_DEPLOYMENT_ERROR_CODE:
  case DELETING_PREFCENTER_ERROR_CODE:
    var result = Object.assign({}, state, {
      error: action.error
    });

    return result;

  default:
    return state;
  }
}

export default preferenceCenters;
