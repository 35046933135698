import { combineReducers } from 'redux';
import app from './reduceApp';
import user from './reduceUsers';
import analytics from './reduceAnalytics';
import client from './reduceClients';
import templates from './reduceTemplates';
import content from './reduceContent';
import preferenceCenters from './reducePreferenceCenters';
import pcDeployments from './reducePCDeployments';
import applicationError from './reduceApplicationError';
import applicationNotification from './reduceApplicationNotification';
import session from './reduceSession';

const appReducer = combineReducers({
  app,
  user,
  analytics,
  client,
  templates,
  preferenceCenters,
  pcDeployments,
  content,
  applicationError,
  applicationNotification,
  session
});

const prefApp = (state, action) => {
  switch (action.type) {
  case 'LOGGED_OUT':
    state = undefined;
    break;
  }
  return appReducer(state, action);
};

export default prefApp;
