export const removeNonUpdatablePrefCenterProps = (objectToClean) => {
  // a better approach from the start would have been putting user modified props into a set on the doc record with a specific name like userInput
  // this method is not an ideal dependency
  // can fix this by grouping the props on the record into userInput and then changing all references - in apps (client, admin), lambdas
  // or create a meta global table per env that holds this information, that way we are not storing the valid prop collection on every record
  let validKeys = ['id', 'name', 'pageTitle', 'layout', 'template', 'styles', 'webAnalytics', 'faviconLocation', 'campaign', 'authRequired', 'pageStyles',
    'colors', 'colorSetId', 'authEmailExpiration', 'authEmailHtml', 'authEmailSubject', 'imageList', 'partnerLists',
    'notify', 'profileSource', 'previewPerson', 'chatBots', 'greeting', 'fetchCurrentFields', 'addlLinkParams', 'language'];

  // loop through the keys on the provided object and remove the keys that are not in the valid keys array
  let objectKeys = Object.keys(objectToClean);

  if (objectKeys && objectKeys.length) {
    for (let ii = 0; ii < objectKeys.length; ii++) {
      if (!validKeys.includes(objectKeys[ii])) {
        delete objectToClean[objectKeys[ii]];
      }
    }
  }

  return objectToClean;
};
