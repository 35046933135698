import { CLEAR_REDUX_STATE, CLEAR_PREF_CTR_DEPLOYMENT, CLEAR_PREF_CTR_DEPLOYMENTS, FETCHED_PREF_CENTER_DEPLOYMENT,
  FETCHED_PREF_CENTER_DEPLOYMENTS, DELETED_PREF_CENTER_DEPLOYMENT_ACTION_COMPLETED, FETCHING_PREF_CENTER_DEPLOYMENT_ERROR_CODE,
  FETCHING_PREF_CENTER_DEPLOYMENTS_ERROR_CODE, DELETING_PREF_CENTER_DEPLOYMENT_ERROR_CODE } from '../actions/action_generators/PreferablyActions';

import * as immutable from 'object-path-immutable';

const initialState = { pcDeployment: {}, pcDeployments: null };

function pcDeployments(state = initialState, action) {
  switch (action.type) {

  case CLEAR_REDUX_STATE:
    return initialState;

  case CLEAR_PREF_CTR_DEPLOYMENT:
    return {
      ...state,
      pcDeployment: {}
    };

  case CLEAR_PREF_CTR_DEPLOYMENTS:
    return {
      ...state,
      pcDeployments: null
    };

  case FETCHED_PREF_CENTER_DEPLOYMENT:

    return {
      ...state,
      pcDeployment: action.deployment
    };

  case FETCHED_PREF_CENTER_DEPLOYMENTS:

    return {
      ...state,
      pcDeployments: action.deployments
    };

  case DELETED_PREF_CENTER_DEPLOYMENT_ACTION_COMPLETED:
    var newState = {};
    for (var ii = 0; ii < state.pcDeployments.length; ii++) {
      if ( state.pcDeployments[ii].id === action.id) {
        newState = immutable.del(state, 'pcDeployments.' + ii);
        break;
      }
    }

    if (newState) {
      return newState;

    } else {
      return state;
    }

  case FETCHING_PREF_CENTER_DEPLOYMENT_ERROR_CODE:
  case FETCHING_PREF_CENTER_DEPLOYMENTS_ERROR_CODE:
  case DELETING_PREF_CENTER_DEPLOYMENT_ERROR_CODE:
    return Object.assign({}, state, {
      error: action.error
    });

  default:
    return state;
  }
}
export default pcDeployments;
