import { applyMiddleware, compose, createStore } from 'redux';
import prefApp from './reducers';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';

const googleAnalytics = () => next => action => {
  if (action) {
    // change once GTM is sorted from a CSP perspecitve
    // dataLayer = dataLayer || [];
    const dataLayer = [];
    dataLayer.push({
      event: action.type,
      context: action.gaContext
    });

    let result = next(action);
    return result;
  }
};

const persistConfig = {
  key: 'app_preferably',
  slicer: () => (state) => {
    let persisted = {
      session: state.session,
      preferenceCenters: state.preferenceCenters,
      templates: state.templates
      // ,client: state.client
    };

    return persisted;
  }
};

const c = compose (
  applyMiddleware(googleAnalytics, thunk),
  persistState([], persistConfig)
);

export const store = createStore(prefApp, c);